.App {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
}

header {
  text-align: left;
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 1s ease-in;
}

h1 {
  margin: 0;
  font-size: clamp(1.1rem, 2.5vw, 1.3rem); /* Reduced from 1.2rem, 3vw, 1.5rem */
  font-weight: 500;
}
p {
  font-weight: 500;
}
.update-time {
  margin: 0;
  color: #666;
  font-size: clamp(0.8rem, 2vw, 0.9rem);
}

.quote-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transform: translateY(-7.5%);
}

blockquote {
  text-align: center;
  max-width: min(800px, 90vw);
  margin: 0;
  padding: 0;
}

.quote-text {
  font-size: clamp(1.5rem, 4.5vw, 2.5rem);
  margin-bottom: 1rem;
  line-height: 1.2;
}

.quote-author {
  text-align: center;
  width: 100%;
  margin-top: 1rem;
  cursor: pointer;
}

.author-text,
.submitter-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.author-text.fade-out,
.submitter-text.fade-out {
  opacity: 0;
  transform: translate(-50%, -10px);
  pointer-events: none;
}

.author-text.fade-in,
.submitter-text.fade-in {
  opacity: 1;
  transform: translate(-50%, 0);
}

.quote-author > span {
  margin-left: 0.5em;
}

.submit-button {
  text-decoration: underline;
  background: transparent;
  border: none;
  font-size: clamp(0.8rem, 2vw, 1rem);
  cursor: pointer;
  padding: 0.5rem 1rem;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
  margin: 0;
  position: relative;
  z-index: 1;
}

.submit-button:hover {
  opacity: 0.7;
  
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, 
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", 
    "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
  box-sizing: border-box;
}

/* Only show cursor when typing */
.quote-text.typing::after {
  content: '|';
  animation: blink 1s step-end infinite;
}

/* Explicitly hide cursor when not typing */
.quote-text::after {
  content: '';
}

@keyframes blink {
  from, to { opacity: 1; }
  50% { opacity: 0; }
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1rem 0.5rem;
  position: relative;
  z-index: 1;
}

.header-content > div {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.date-footer {
  position: static;
  margin-top: auto;
  text-align: center;
  font-size: clamp(0.7rem, 1.5vw, 0.8rem);
  color: #666;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  padding-bottom: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.modal {
  background: white;
  padding: 2rem;
  border-radius: 4px;
  width: 90%;
  max-width: 500px;
  position: relative;
  animation: scaleIn 0.3s ease-out;
  transform-origin: center center;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  width: 2rem;
  height: 2rem;
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group {
  margin-bottom: 12px;
}

.form-group label {
  margin-bottom: 4px;
  display: block;
}

.form-group input,
.form-group textarea {
  margin-top: 2px;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.submit-form-button {
  background: black;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  height: 45px; /* Fixed height to prevent jumping */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  font-size: 0.85rem;
  font-weight: 600;
}

.submit-form-button:hover {
  opacity: 0.9;
}

.submit-form-button:disabled {
  background: #333;
  cursor: not-allowed;
}

/* Update the submit quote button to open modal */
.submit-button {
  /* ... existing styles ... */
  cursor: pointer;
}

/* Spinner Animation */
.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Success Check Animation */
.success-check {
  color: white;
  font-size: 1.2rem;
  animation: scale-in 0.3s ease-out;
}

@keyframes scale-in {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.hidden {
  display: none !important;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-overlay.closing {
  animation: fadeOut 0.2s ease-in forwards;
}

.modal.closing {
  animation: scaleOut 0.2s ease-in forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.95);
    opacity: 0;
  }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.bounce {
  animation: bounce 0.3s ease;
}
html {
  background-color: white;
}

body {
  background-color: white;
  min-height: 100vh;
}

/* Add media query for larger screens if you want to keep original padding */
@media (min-width: 768px) {
  .header-content {
    padding: 1rem 1rem;
  }
}

.character-count {
  color: #666;
  font-size: 0.8em;
  text-align: right;
  margin-top: 4px;
}

.error-text {
  color: #dc3545;
  font-size: 0.8em;
  margin-top: 4px;
}

.submission-note {
  font-size: 0.8rem;
  color: #666;
  margin-top: 8px;
  text-align: center;
}

.modal h2 {
  font-weight: 550; /* or 500 for semi-bold */
}